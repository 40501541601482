import React, { useState } from "react";
import { EnvelopeClosedIcon, ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import { IconSpinner } from "../../ui/Icons";

export const sendEmailSchema = {
  send_email: {
    parameters: {
      type: "object",
      required: ["to", "subject", "html"],
      properties: {
        to: {
          type: "array",
          items: {
            type: "string",
          },
          description: "List of recipient email addresses",
        },
        subject: {
          type: "string",
          description: "The email subject",
        },
        html: {
          type: "string",
          description: "The HTML content of the email",
        },
      },
    },
    description: "Send an email to specified recipients",
  },
};

export const toolMetadata = {
  name: "send_email",
  displayName: "Send Email",
  description: "Send an email to a specified recipient",
};

const SendEmail = ({ invocation }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!invocation) {
    return null;
  }

  const { args, result } = invocation;

  // Determine if the result is ready by checking if the result object exists and has the necessary properties
  const isReady = result && (result.status === "success" || result.status === "error");

 return (
    <div className="border rounded-lg overflow-hidden">
      <div 
        className={`flex items-center justify-between p-3 bg-white ${isReady ? 'cursor-pointer' : ''}`}
        onClick={() => isReady && setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center space-x-2">
          {!isReady ? <IconSpinner className="w-5 h-5" /> : <EnvelopeClosedIcon className="w-5 h-5" />}
          <span className="font-medium">
            Sending Email to {args.to.length} recipient{args.to.length > 1 ? 's' : ''}
          </span>
        </div>
        <div className="flex items-center">
          {isReady && (
            isExpanded ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />
          )}
        </div>
      </div>
      
      {isReady && isExpanded && (
        <div className="p-4 bg-white">
          {result.status === "success" ? (
            <div className="space-y-4">
              <div className="border-b pb-2">
                <div className="font-medium">Email Sent</div>
                <div className="mt-1 text-sm">To: {args.to.join(", ")}</div>
              </div>
              <div>
                <div className="font-medium">Subject</div>
                <div className="mt-1 text-sm">{args.subject}</div>
              </div>
              <div>
                <div className="font-medium">Body</div>
                <div className="mt-1 text-sm" dangerouslySetInnerHTML={{ __html: args.html }}></div>
              </div>
            </div>
          ) : (
            <div className="text-red-600">Error sending email: {result.message || "Unknown error"}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default SendEmail;