export function getSupportedModels(userInfo) {
  if (!userInfo || !userInfo.organizations || userInfo.organizations.length === 0) {
    return [];
  }

  const connections = userInfo.organizations[0].connections;

  const allModels = [
    {
      name: "o1-preview",
      provider: "OpenAI",
      providerKey: "openai",
      key: "openai:o1-preview",
      tokens: 128000,
      vision: false,
    },
    {
      name: "o1-mini",
      provider: "OpenAI",
      providerKey: "openai",
      key: "openai:o1-mini",
      tokens: 128000,
      vision: false,
    },
    {
      name: "gpt-4o",
      provider: "OpenAI",
      providerKey: "openai",
      key: "openai:gpt-4o",
      tokens: 128000,
      vision: true,
    },
    {
      name: "gpt-4o-mini",
      provider: "OpenAI",
      providerKey: "openai",
      key: "openai:gpt-4o-mini",
      tokens: 128000,
      vision: true,
    },
    {
      name: "claude-3-5-sonnet-20240620",
      provider: "Anthropic",
      providerKey: "anthropic",
      key: "anthropic:claude-3-5-sonnet-20240620",
      tokens: 200000,
      vision: true,
    },
    {
      name: connections.azure_openai?.DEPLOYMENT_NAME,
      provider: "Azure OpenAI",
      providerKey: "azure_openai",
      key: `azure:${connections.azure_openai?.DEPLOYMENT_NAME}`,
      tokens: 128000,
      vision: true,
    },
  ];

  return allModels.filter((model) => connections[model.providerKey]);
}