import React, { useState } from "react";
import { PieChartIcon, ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import { IconSpinner } from "../../ui/Icons";

// Enums for sport, markets, and bookmakers
const SPORTS = {
  CFL: "americanfootball_cfl",
  NCAAF: "americanfootball_ncaaf",
  NCAAF_CHAMPIONSHIP_WINNER: "americanfootball_ncaaf_championship_winner",
  NFL: "americanfootball_nfl",
  NFL_SUPER_BOWL_WINNER: "americanfootball_nfl_super_bowl_winner",
  AFL: "aussierules_afl",
  MILB: "baseball_milb",
  MLB: "baseball_mlb",
  MLB_WORLD_SERIES_WINNER: "baseball_mlb_world_series_winner",
  NBA: "basketball_nba",
  NBA_CHAMPIONSHIP_WINNER: "basketball_nba_championship_winner",
  NCAAB_CHAMPIONSHIP_WINNER: "basketball_ncaab_championship_winner",
  WNBA: "basketball_wnba",
  BOXING: "boxing_boxing",
  CPLT20: "cricket_caribbean_premier_league",
  INTERNATIONAL_T20: "cricket_international_t20",
  T20_BLAST: "cricket_t20_blast",
  TEST_MATCH: "cricket_test_match",
  MASTERS: "golf_masters_tournament_winner",
  PGA: "golf_pga_championship_winner",
  NHL: "icehockey_nhl",
  NHL_CHAMPIONSHIP_WINNER: "icehockey_nhl_championship_winner",
  HOCKEYALLSVENSKAN: "icehockey_sweden_allsvenskan",
  SHL: "icehockey_sweden_hockey_league",
  PLL: "lacrosse_pll",
  MMA: "mma_mixed_martial_arts",
  US_PRESIDENTIAL_ELECTION_WINNER: "politics_us_presidential_election_winner",
  NRL: "rugbyleague_nrl",
  ARGENTINA_PRIMERA_DIVISION: "soccer_argentina_primera_division",
  AUSTRIA_BUNDESLIGA: "soccer_austria_bundesliga",
  BELGIUM_FIRST_DIV: "soccer_belgium_first_div",
  BRAZIL_CAMPEONATO: "soccer_brazil_campeonato",
  BRAZIL_SERIE_B: "soccer_brazil_serie_b",
  CHILE_CAMPEONATO: "soccer_chile_campeonato",
  CONMEBOL_COPA_LIBERTADORES: "soccer_conmebol_copa_libertadores",
  DENMARK_SUPERLIGA: "soccer_denmark_superliga",
  EFL_CHAMP: "soccer_efl_champ",
  ENGLAND_LEAGUE1: "soccer_england_league1",
  ENGLAND_LEAGUE2: "soccer_england_league2",
  EPL: "soccer_epl",
  FIFA_WORLD_CUP_WINNER: "soccer_fifa_world_cup_winner",
  FRANCE_LIGUE_ONE: "soccer_france_ligue_one",
  GERMANY_BUNDESLIGA: "soccer_germany_bundesliga",
  GERMANY_BUNDESLIGA2: "soccer_germany_bundesliga2",
  GREECE_SUPER_LEAGUE: "soccer_greece_super_league",
  ITALY_SERIE_A: "soccer_italy_serie_a",
  ITALY_SERIE_B: "soccer_italy_serie_b",
  JAPAN_J_LEAGUE: "soccer_japan_j_league",
  KOREA_KLEAGUE1: "soccer_korea_kleague1",
  LEAGUE_OF_IRELAND: "soccer_league_of_ireland",
  MEXICO_LIGAMX: "soccer_mexico_ligamx",
  NETHERLANDS_EREDIVISIE: "soccer_netherlands_eredivisie",
  NORWAY_ELITESERIEN: "soccer_norway_eliteserien",
  PORTUGAL_PRIMEIRA_LIGA: "soccer_portugal_primeira_liga",
  SPAIN_LA_LIGA: "soccer_spain_la_liga",
  SPAIN_SEGUNDA_DIVISION: "soccer_spain_segunda_division",
  SPL: "soccer_spl",
  SWEDEN_ALLSVENSKAN: "soccer_sweden_allsvenskan",
  SWEDEN_SUPERETTAN: "soccer_sweden_superettan",
  TURKEY_SUPER_LEAGUE: "soccer_turkey_super_league",
  UEFA_CHAMPS_LEAGUE: "soccer_uefa_champs_league",
  UEFA_CHAMPS_LEAGUE_QUALIFICATION: "soccer_uefa_champs_league_qualification",
  UEFA_EUROPA_CONFERENCE_LEAGUE: "soccer_uefa_europa_conference_league",
  UEFA_EUROPA_LEAGUE: "soccer_uefa_europa_league",
  UEFA_NATIONS_LEAGUE: "soccer_uefa_nations_league",
  MLS: "soccer_usa_mls",
  UFC: "mma_mixed_martial_arts",
  PGA: "golf_pga_championship_winner",
  MASTERS: "golf_masters_tournament_winner",
};

const MARKETS = {
  H2H: "h2h",
  SPREADS: "spreads",
  TOTALS: "totals",
  OUTRIGHTS: "outrights",
  ALTERNATE_SPREADS: "alternate_spreads",
  ALTERNATE_TOTALS: "alternate_totals",
  BTTS: "btts",
  DRAW_NO_BET: "draw_no_bet",
  H2H_3_WAY: "h2h_3_way",
  TEAM_TOTALS: "team_totals",
  ALTERNATE_TEAM_TOTALS: "alternate_team_totals",
  H2H_Q1: "h2h_q1",
  H2H_Q2: "h2h_q2",
  H2H_Q3: "h2h_q3",
  H2H_Q4: "h2h_q4",
  H2H_H1: "h2h_h1",
  H2H_H2: "h2h_h2",
  H2H_P1: "h2h_p1",
  H2H_P2: "h2h_p2",
  H2H_P3: "h2h_p3",
  H2H_1ST_1_INNINGS: "h2h_1st_1_innings",
  H2H_1ST_3_INNINGS: "h2h_1st_3_innings",
  H2H_1ST_5_INNINGS: "h2h_1st_5_innings",
  H2H_1ST_7_INNINGS: "h2h_1st_7_innings",
  H2H_3_WAY_1ST_1_INNINGS: "h2h_3_way_1st_1_innings",
  H2H_3_WAY_1ST_3_INNINGS: "h2h_3_way_1st_3_innings",
  H2H_3_WAY_1ST_5_INNINGS: "h2h_3_way_1st_5_innings",
  H2H_3_WAY_1ST_7_INNINGS: "h2h_3_way_1st_7_innings",
  SPREADS_Q1: "spreads_q1",
  SPREADS_Q2: "spreads_q2",
  SPREADS_Q3: "spreads_q3",
  SPREADS_Q4: "spreads_q4",
  SPREADS_H1: "spreads_h1",
  SPREADS_H2: "spreads_h2",
  SPREADS_P1: "spreads_p1",
  SPREADS_P2: "spreads_p2",
  SPREADS_P3: "spreads_p3",
  SPREADS_1ST_1_INNINGS: "spreads_1st_1_innings",
  SPREADS_1ST_3_INNINGS: "spreads_1st_3_innings",
  SPREADS_1ST_5_INNINGS: "spreads_1st_5_innings",
  SPREADS_1ST_7_INNINGS: "spreads_1st_7_innings",
  ALTERNATE_SPREADS_1ST_1_INNINGS: "alternate_spreads_1st_1_innings",
  ALTERNATE_SPREADS_1ST_3_INNINGS: "alternate_spreads_1st_3_innings",
  ALTERNATE_SPREADS_1ST_5_INNINGS: "alternate_spreads_1st_5_innings",
  ALTERNATE_SPREADS_1ST_7_INNINGS: "alternate_spreads_1st_7_innings",
  ALTERNATE_SPREADS_Q1: "alternate_spreads_q1",
  ALTERNATE_SPREADS_H1: "alternate_spreads_h1",
  TOTALS_Q1: "totals_q1",
  TOTALS_Q2: "totals_q2",
  TOTALS_Q3: "totals_q3",
  TOTALS_Q4: "totals_q4",
  TOTALS_H1: "totals_h1",
  TOTALS_H2: "totals_h2",
  TOTALS_P1: "totals_p1",
  TOTALS_P2: "totals_p2",
  TOTALS_P3: "totals_p3",
  TOTALS_1ST_1_INNINGS: "totals_1st_1_innings",
  TOTALS_1ST_3_INNINGS: "totals_1st_3_innings",
  TOTALS_1ST_5_INNINGS: "totals_1st_5_innings",
  TOTALS_1ST_7_INNINGS: "totals_1st_7_innings",
  ALTERNATE_TOTALS_1ST_1_INNINGS: "alternate_totals_1st_1_innings",
  ALTERNATE_TOTALS_1ST_3_INNINGS: "alternate_totals_1st_3_innings",
  ALTERNATE_TOTALS_1ST_5_INNINGS: "alternate_totals_1st_5_innings",
  ALTERNATE_TOTALS_1ST_7_INNINGS: "alternate_totals_1st_7_innings",
  ALTERNATE_TOTALS_Q1: "alternate_totals_q1",
  ALTERNATE_TOTALS_H1: "alternate_totals_h1",
  TEAM_TOTALS_H1: "team_totals_h1",
  TEAM_TOTALS_H2: "team_totals_h2",
  TEAM_TOTALS_Q1: "team_totals_q1",
  TEAM_TOTALS_Q2: "team_totals_q2",
  TEAM_TOTALS_Q3: "team_totals_q3",
  TEAM_TOTALS_Q4: "team_totals_q4",
  ALTERNATE_TEAM_TOTALS_H1: "alternate_team_totals_h1",
  ALTERNATE_TEAM_TOTALS_H2: "alternate_team_totals_h2",
  PLAYER_PASS_TDS: "player_pass_tds",
  PLAYER_PASS_YDS: "player_pass_yds",
  PLAYER_PASS_COMPLETIONS: "player_pass_completions",
  PLAYER_PASS_ATTEMPTS: "player_pass_attempts",
  PLAYER_PASS_INTERCEPTIONS: "player_pass_interceptions",
  PLAYER_PASS_LONGEST_COMPLETION: "player_pass_longest_completion",
  PLAYER_RUSH_YDS: "player_rush_yds",
  PLAYER_RUSH_ATTEMPTS: "player_rush_attempts",
  PLAYER_RUSH_LONGEST: "player_rush_longest",
  PLAYER_RECEPTIONS: "player_receptions",
  PLAYER_RECEPTION_YDS: "player_reception_yds",
  PLAYER_RECEPTION_LONGEST: "player_reception_longest",
  PLAYER_KICKING_POINTS: "player_kicking_points",
  PLAYER_FIELD_GOALS: "player_field_goals",
  PLAYER_TACKLES_ASSISTS: "player_tackles_assists",
  PLAYER_1ST_TD: "player_1st_td",
  PLAYER_LAST_TD: "player_last_td",
  PLAYER_ANYTIME_TD: "player_anytime_td",
  PLAYER_PASS_TDS_ALTERNATE: "player_pass_tds_alternate",
  PLAYER_PASS_YDS_ALTERNATE: "player_pass_yds_alternate",
  PLAYER_RUSH_YDS_ALTERNATE: "player_rush_yds_alternate",
  PLAYER_RUSH_RECEPTION_YDS_ALTERNATE: "player_rush_reception_yds_alternate",
  PLAYER_RECEPTION_YDS_ALTERNATE: "player_reception_yds_alternate",
  PLAYER_RECEPTIONS_ALTERNATE: "player_receptions_alternate",
  PLAYER_POINTS: "player_points",
  PLAYER_REBOUNDS: "player_rebounds",
  PLAYER_ASSISTS: "player_assists",
  PLAYER_THREES: "player_threes",
  PLAYER_BLOCKS: "player_blocks",
  PLAYER_STEALS: "player_steals",
  PLAYER_BLOCKS_STEALS: "player_blocks_steals",
  PLAYER_TURNOVERS: "player_turnovers",
  PLAYER_POINTS_REBOUNDS_ASSISTS: "player_points_rebounds_assists",
  PLAYER_POINTS_REBOUNDS: "player_points_rebounds",
  PLAYER_POINTS_ASSISTS: "player_points_assists",
  PLAYER_REBOUNDS_ASSISTS: "player_rebounds_assists",
  PLAYER_FIRST_BASKET: "player_first_basket",
  PLAYER_DOUBLE_DOUBLE: "player_double_double",
  PLAYER_TRIPLE_DOUBLE: "player_triple_double",
  PLAYER_POINTS_ALTERNATE: "player_points_alternate",
  PLAYER_REBOUNDS_ALTERNATE: "player_rebounds_alternate",
  PLAYER_ASSISTS_ALTERNATE: "player_assists_alternate",
  PLAYER_BLOCKS_ALTERNATE: "player_blocks_alternate",
  PLAYER_STEALS_ALTERNATE: "player_steals_alternate",
  PLAYER_THREES_ALTERNATE: "player_threes_alternate",
  PLAYER_POINTS_ASSISTS_ALTERNATE: "player_points_assists_alternate",
  PLAYER_POINTS_REBOUNDS_ALTERNATE: "player_points_rebounds_alternate",
  PLAYER_REBOUNDS_ASSISTS_ALTERNATE: "player_rebounds_assists_alternate",
  PLAYER_POINTS_REBOUNDS_ASSISTS_ALTERNATE: "player_points_rebounds_assists_alternate",
  BATTER_HOME_RUNS: "batter_home_runs",
  BATTER_FIRST_HOME_RUN: "batter_first_home_run",
  BATTER_HITS: "batter_hits",
  BATTER_TOTAL_BASES: "batter_total_bases",
  BATTER_RBIS: "batter_rbis",
  BATTER_RUNS_SCORED: "batter_runs_scored",
  BATTER_HITS_RUNS_RBIS: "batter_hits_runs_rbis",
  BATTER_SINGLES: "batter_singles",
  BATTER_DOUBLES: "batter_doubles",
  BATTER_TRIPLES: "batter_triples",
  BATTER_WALKS: "batter_walks",
  BATTER_STRIKEOUTS: "batter_strikeouts",
  BATTER_STOLEN_BASES: "batter_stolen_bases",
  PITCHER_STRIKEOUTS: "pitcher_strikeouts",
  PITCHER_RECORD_A_WIN: "pitcher_record_a_win",
  PITCHER_HITS_ALLOWED: "pitcher_hits_allowed",
  PITCHER_WALKS: "pitcher_walks",
  PITCHER_EARNED_RUNS: "pitcher_earned_runs",
  PITCHER_OUTS: "pitcher_outs",
  BATTER_TOTAL_BASES_ALTERNATE: "batter_total_bases_alternate",
  BATTER_HOME_RUNS_ALTERNATE: "batter_home_runs_alternate",
  BATTER_HITS_ALTERNATE: "batter_hits_alternate",
  BATTER_RBIS_ALTERNATE: "batter_rbis_alternate",
  PITCHER_HITS_ALLOWED_ALTERNATE: "pitcher_hits_allowed_alternate",
  PITCHER_WALKS_ALTERNATE: "pitcher_walks_alternate",
  PITCHER_STRIKEOUTS_ALTERNATE: "pitcher_strikeouts_alternate",
  PLAYER_POINTS: "player_points",
  PLAYER_POWER_PLAY_POINTS: "player_power_play_points",
  PLAYER_ASSISTS: "player_assists",
  PLAYER_BLOCKED_SHOTS: "player_blocked_shots",
  PLAYER_SHOTS_ON_GOAL: "player_shots_on_goal",
  PLAYER_GOALS: "player_goals",
  PLAYER_TOTAL_SAVES: "player_total_saves",
  PLAYER_GOAL_SCORER_FIRST: "player_goal_scorer_first",
  PLAYER_GOAL_SCORER_LAST: "player_goal_scorer_last",
  PLAYER_GOAL_SCORER_ANYTIME: "player_goal_scorer_anytime",
  PLAYER_POINTS_ALTERNATE: "player_points_alternate",
  PLAYER_ASSISTS_ALTERNATE: "player_assists_alternate",
  PLAYER_POWER_PLAY_POINTS_ALTERNATE: "player_power_play_points_alternate",
  PLAYER_GOALS_ALTERNATE: "player_goals_alternate",
  PLAYER_SHOTS_ON_GOAL_ALTERNATE: "player_shots_on_goal_alternate",
  PLAYER_BLOCKED_SHOTS_ALTERNATE: "player_blocked_shots_alternate",
  PLAYER_TOTAL_SAVES_ALTERNATE: "player_total_saves_alternate",
  PLAYER_DISPOSALS: "player_disposals",
  PLAYER_DISPOSALS_OVER: "player_disposals_over",
  PLAYER_GOAL_SCORER_FIRST: "player_goal_scorer_first",
  PLAYER_GOAL_SCORER_LAST: "player_goal_scorer_last",
  PLAYER_GOAL_SCORER_ANYTIME: "player_goal_scorer_anytime",
  PLAYER_GOALS_SCORED_OVER: "player_goals_scored_over",
  PLAYER_MARKS_OVER: "player_marks_over",
  PLAYER_MARKS_MOST: "player_marks_most",
  PLAYER_TACKLES_OVER: "player_tackles_over",
  PLAYER_TACKLES_MOST: "player_tackles_most",
  PLAYER_AFL_FANTASY_POINTS: "player_afl_fantasy_points",
  PLAYER_AFL_FANTASY_POINTS_OVER: "player_afl_fantasy_points_over",
  PLAYER_AFL_FANTASY_POINTS_MOST: "player_afl_fantasy_points_most",
  PLAYER_TRY_SCORER_FIRST: "player_try_scorer_first",
  PLAYER_TRY_SCORER_LAST: "player_try_scorer_last",
  PLAYER_TRY_SCORER_ANYTIME: "player_try_scorer_anytime",
  PLAYER_TRY_SCORER_OVER: "player_try_scorer_over",
  PLAYER_GOAL_SCORER_ANYTIME: "player_goal_scorer_anytime",
  PLAYER_FIRST_GOAL_SCORER: "player_first_goal_scorer",
  PLAYER_LAST_GOAL_SCORER: "player_last_goal_scorer",
  PLAYER_TO_RECEIVE_CARD: "player_to_receive_card",
  PLAYER_TO_RECEIVE_RED_CARD: "player_to_receive_red_card",
  PLAYER_SHOTS_ON_TARGET: "player_shots_on_target",
  PLAYER_SHOTS: "player_shots",
  PLAYER_ASSISTS: "player_assists",
  ALTERNATE_SPREADS_CORNERS: "alternate_spreads_corners",
  ALTERNATE_TOTALS_CORNERS: "alternate_totals_corners",
  ALTERNATE_SPREADS_CARDS: "alternate_spreads_cards",
  ALTERNATE_TOTALS_CARDS: "alternate_totals_cards",
  DOUBLE_CHANCE: "double_chance",
};

const BOOKMAKERS = {
  DRAFTKINGS: "draftkings",
  FANDUEL: "fanduel",
  BETONLINEAG: "betonlineag",
  BETMGM: "betmgm",
  BETRIVERS: "betrivers",
  BETUS: "betus",
  BOVADA: "bovada",
  WILLIAMHILL_US: "williamhill_us",
  LOWVIG: "lowvig",
  MYBOOKIEAG: "mybookieag",
  SUPERBOOK: "superbook",
  UNIBET_US: "unibet_us",
};

// Tool definition and schema
export const getEventOddsSchema = {
  get_event_odds: {
    parameters: {
      type: "object",
      required: ["eventId", "sport", "markets", "bookmakers"],
      properties: {
        eventId: {
          type: "string",
          description: "The ID of the event to get odds for",
        },
        sport: {
          type: "string",
          enum: Object.values(SPORTS),
          description: "The sport key (e.g., 'americanfootball_nfl')",
        },
        markets: {
          type: "array",
          items: {
            type: "string",
            enum: Object.values(MARKETS),
          },
          description: "The markets to retrieve odds for",
        },
        bookmakers: {
          type: "array",
          items: {
            type: "string",
            enum: Object.values(BOOKMAKERS),
          },
          description: "The bookmakers to retrieve odds for",
        },
      },
    },
    description: "Get odds for a specific event",
  },
};

export const toolMetadata = {
  name: "get_event_odds",
  displayName: "Get Event Odds",
  description: "Retrieve odds for a specific event",
};

const GetEventOdds = ({ invocation }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!invocation || !invocation.args) {
    return null;
  }

  const { result, args } = invocation;

  const isReady = result && result.status;

  return (
    <div className="border rounded-lg overflow-hidden">
      <div className={`flex items-center justify-between p-3 bg-white ${isReady ? "cursor-pointer" : ""}`} onClick={() => isReady && setIsExpanded(!isExpanded)}>
        <div className="flex items-center space-x-2">
          {!isReady ? <IconSpinner className="w-5 h-5" /> : <PieChartIcon className="w-5 h-5" />}
          <span className="font-medium">Getting odds for event: {args.eventId}</span>
        </div>
        <div className="flex items-center">{isReady && (isExpanded ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />)}</div>
      </div>

      {isReady && isExpanded && (
        <div className="p-4 bg-white">
          {result.status === "success" ? (
            <div className="space-y-4">
              <div>
                <strong>Event ID:</strong> {args.eventId}
              </div>
              <div>
                <strong>Sport:</strong> {args.sport}
              </div>
              <div className="border-b pb-2">
                <div className="font-medium">Odds Data</div>
                <pre className="mt-1 text-sm whitespace-pre-wrap">{JSON.stringify(result.result, null, 2)}</pre>
              </div>
            </div>
          ) : (
            <div className="text-red-600">Error getting odds: {result.error || "Unknown error"}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default GetEventOdds;