"use client"

import * as React from "react"
import { CheckIcon, CaretSortIcon } from "@radix-ui/react-icons"

import { useAI } from "../../contexts/AIContext";

import { cn } from "../../lib/utils"
import { Button } from "../../ui/Button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../ui/Command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../ui/Popover"

export function AgentCombobox({ selectedAgent, setSelectedAgent }) {
  const [open, setOpen] = React.useState(false)
  const { userInfo, agents, isLoadingAgents } = useAI();


  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[200px] justify-between"
        >
                    {selectedAgent
            ? agents.find((agent) => agent.id === selectedAgent)?.name
            : "Filter by agent..."}
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput placeholder="Filter by agent..." />
          <CommandList>
            <CommandEmpty>No agent found.</CommandEmpty>
            <CommandGroup>
              {agents.map((agent) => (
                <CommandItem
                  key={agent.id}
                  value={agent.id}
                  onSelect={(currentValue) => {
                    setSelectedAgent(agent.id);
                    setOpen(false)
                  }}
                >
                  <CheckIcon
                    className={cn(
                      "mr-2 h-4 w-4",
                      selectedAgent === agent.id ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {agent.name}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}