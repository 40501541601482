import { Outlet, useNavigate } from 'react-router-dom'
import { ClerkProvider } from '@clerk/clerk-react'
import { AIContextProvider } from '../contexts/AIContext';
import { ConversationContextProvider } from '../contexts/ConversationContext';
import { Toaster } from "sonner";

const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key")
}

export default function RootLayout() {
  const navigate = useNavigate();

  return (
    <ClerkProvider
      routerPush={(to) => navigate(to)}
      routerReplace={(to) => navigate(to, { replace: true })}
      publishableKey={PUBLISHABLE_KEY}
      afterSignOutUrl="/"
      signInForceRedirectUrl="/chat"  // Redirect to /chat after sign in
      signUpForceRedirectUrl="/chat"  // Redirect to /chat after sign up
    >
      <AIContextProvider>
        <ConversationContextProvider>
          <Outlet />
          <Toaster position="top-right" />
        </ConversationContextProvider>
      </AIContextProvider>
    </ClerkProvider>
  )
}