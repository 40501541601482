import React from 'react';
import { useRouteError } from 'react-router-dom';

function ErrorBoundary() {
  const error = useRouteError();
  console.error(error);

  return (
    <div className="error-container">
      <h1>Oops! Something went wrong.</h1>
      <p>We're sorry, but an error occurred. Please try again later.</p>
      {process.env.NODE_ENV === 'development' && (
        <p>
          <strong>Error:</strong> {error.message}
        </p>
      )}
    </div>
  );
}

export default ErrorBoundary;