import React, { useState, useEffect, useCallback } from "react";
import { Switch, Dialog, Transition, TransitionChild, DialogPanel, DialogTitle } from "@headlessui/react";
import { useParams, useNavigate } from "react-router-dom";
import { Pencil1Icon, TrashIcon } from "@radix-ui/react-icons";
import { toast } from "sonner";

import { useAI } from "../../contexts/AIContext";
import { tools, getToolsJson } from "../Tools";
import { getSupportedModels } from "./SupportedModels";
import { useAuthenticatedFetch } from "../../hooks/use-authenticated-fetch";

const ToolSelector = ({ metadata, isSelected, onToggle }) => {
  return (
    <div className="flex items-center justify-between py-2">
      <div className="flex flex-col">
        <span className="text-sm font-medium text-gray-900">{metadata.name}</span>
        <span className="text-xs text-gray-500">{metadata.description}</span>
      </div>
      <Switch
        checked={isSelected}
        onChange={onToggle}
        className={`${isSelected ? "bg-indigo-600" : "bg-gray-200"} relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
      >
        <span className={`${isSelected ? "translate-x-6" : "translate-x-1"} inline-block h-4 w-4 transform rounded-full bg-white transition-transform`} />
      </Switch>
    </div>
  );
};

const AgentConfig = () => {
  const { agentId } = useParams();
  const navigate = useNavigate();
  const { userInfo, models, fetchAgents, agents, isLoadingAgents, error } = useAI();
  const authenticatedFetch = useAuthenticatedFetch();
  const [isNewAgent, setIsNewAgent] = useState(true);

  const [name, setName] = useState("New Agent");
  const [description, setDescription] = useState("");
  const [model, setModel] = useState("openai:gpt-4o");
  const [maxToolRoundtrips, setMaxToolRoundtrips] = useState(20);
  const [logoUrl, setLogoUrl] = useState("");
  const [instructions, setInstructions] = useState("");
  const [selectedTools, setSelectedTools] = useState({});
  const [starters, setStarters] = useState([]);

  const [customTools, setCustomTools] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editingToolIndex, setEditingToolIndex] = useState(null);
  const [newCustomTool, setNewCustomTool] = useState("");

  useEffect(() => {
    if (agentId && agentId !== "new") {
      setIsNewAgent(false);
      const existingAgent = agents.find((agent) => agent.id === parseInt(agentId, 10));
      if (existingAgent) {
        populateForm(existingAgent);
      } else {
        console.error("Agent not found");
        resetForm();
      }
    } else {
      setIsNewAgent(true);
      resetForm();
    }
  }, [agentId, agents]);

  const openDialog = (index = null) => {
    if (index !== null) {
      setNewCustomTool(JSON.stringify(customTools[index], null, 2));
      setEditingToolIndex(index);
    } else {
      setNewCustomTool("");
      setEditingToolIndex(null);
    }
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setNewCustomTool("");
    setEditingToolIndex(null);
  };

  const availableModels = getSupportedModels(userInfo);

  const handleAddOrUpdateCustomTool = useCallback(() => {
    try {
      const toolJson = JSON.parse(newCustomTool);
      const toolName = Object.keys(toolJson)[0];

      if (editingToolIndex !== null) {
        setCustomTools((tools) => tools.map((tool, index) => (index === editingToolIndex ? toolJson : tool)));
      } else {
        setCustomTools((tools) => [...tools, toolJson]);
      }
      closeDialog();
    } catch (error) {
      alert("Invalid JSON. Please check your input.");
    }
  }, [newCustomTool, editingToolIndex]);

  const handleRemoveCustomTool = useCallback((index) => {
    setCustomTools((tools) => tools.filter((_, i) => i !== index));
  }, []);

  const handleSelectExistingTool = useCallback((toolName) => {
    const selectedTool = tools.find((tool) => tool.metadata.name === toolName);
    if (selectedTool) {
      const toolJson = {
        [selectedTool.metadata.name]: {
          ...selectedTool.schema[selectedTool.metadata.name],
          description: selectedTool.metadata.description,
        },
      };
      setNewCustomTool(JSON.stringify(toolJson, null, 2));
    }
  }, []);

  const populateForm = (agent) => {
    setName(agent.name);
    setDescription(agent.description);
    setModel(agent.model);
    setMaxToolRoundtrips(agent.maxToolRoundtrips);
    setLogoUrl(agent.logoUrl);
    setInstructions(agent.systemPrompt);
    setStarters(agent.conversationStarters);

    const agentTools = agent.tools || {};
    const prebuiltToolNames = tools.map((tool) => tool.metadata.name);

    // Separate pre-built tools and custom tools
    const prebuiltTools = {};
    const customToolsArray = [];

    Object.entries(agentTools).forEach(([toolName, toolData]) => {
      if (prebuiltToolNames.includes(toolName)) {
        prebuiltTools[toolName] = true;
      } else {
        customToolsArray.push({ [toolName]: toolData });
      }
    });

    setSelectedTools(prebuiltTools);
    setCustomTools(customToolsArray);
  };

  const resetForm = () => {
    setName("New Agent");
    setDescription("");
    setModel("openai:gpt-4o");
    setMaxToolRoundtrips(20);
    setLogoUrl("");
    setInstructions("");
    setStarters([]);
    setSelectedTools({});
  };

  const handleAddStarter = () => {
    setStarters([...starters, ""]);
  };

  const handleStarterChange = (index, value) => {
    const newStarters = [...starters];
    newStarters[index] = value;
    setStarters(newStarters);
  };

  const handleRemoveStarter = (index) => {
    const newStarters = starters.filter((_, i) => i !== index);
    setStarters(newStarters);
  };

  const handleToolToggle = (toolName) => {
    setSelectedTools((prev) => ({
      ...prev,
      [toolName]: !prev[toolName],
    }));
  };

  const handleSaveConfiguration = async () => {
    try {
      const toolsJson = getToolsJson(selectedTools);
      const customToolsJson = Object.fromEntries(
        customTools.map((tool) => {
          const toolName = Object.keys(tool)[0];
          const toolData = { ...tool[toolName] };
          return [toolName, toolData];
        })
      );
      const allTools = {
        ...toolsJson,
        ...customToolsJson,
      };

      const agentData = {
        ...(isNewAgent ? {} : { id: parseInt(agentId, 10) }),
        name,
        description,
        model,
        maxToolRoundtrips,
        systemPrompt: instructions,
        conversationStarters: starters,
        logoUrl,
        tools: allTools,
      };

      const response = await authenticatedFetch('/api/agents/updateAgent', {
        method: 'POST',
        body: agentData,
      });

      if (response) {
        toast.success(`Agent ${isNewAgent ? "created" : "updated"} successfully!`);
        await fetchAgents(); // Refresh the agents list
        navigate("/control-hub"); // Redirect to agents list
      }
    } catch (error) {
      console.error(`Error ${isNewAgent ? "creating" : "updating"} agent:`, error);
      toast.error(`Failed to ${isNewAgent ? "create" : "update"} agent. Please try again.`);
    }
  };

  const handleDeleteAgent = async () => {
    if (!agentId || agentId === "new") {
      toast.error("Cannot delete a new agent.");
      return;
    }

    if (!window.confirm("Are you sure you want to delete this agent? This action cannot be undone.")) {
      return;
    }

    try {
      const response = await authenticatedFetch(`/api/agents/deleteAgent`, {
        method: 'DELETE',
        body: { agentId: parseInt(agentId, 10) },
      });

      if (response) {
        toast.success("Agent deleted successfully!");
        await fetchAgents(); // Refresh the agents list
        navigate("/control-hub"); // Redirect to agents list
      }
    } catch (error) {
      console.error("Error deleting agent:", error);
      toast.error("Failed to delete agent. Please try again.");
    }
  };

  return (
    <div className="max-w-3xl mx-auto my-8">
      <h2 className="text-2xl font-bold text-gray-900 mb-6">{isNewAgent ? "Create New Agent" : "Edit Agent"}</h2>

      <div className="space-y-6">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Name
          </label>
          <input
            type="text"
            id="name"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div>
          <label htmlFor="description" className="block text-sm font-medium text-gray-700">
            Description
          </label>
          <input
            type="text"
            id="description"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div>
          <label htmlFor="logoUrl" className="block text-sm font-medium text-gray-700">
            Logo URL
          </label>
          <input
            type="text"
            id="logoUrl"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            value={logoUrl}
            onChange={(e) => setLogoUrl(e.target.value)}
          />
        </div>

        <div>
          <label htmlFor="model" className="block text-sm font-medium text-gray-700">
            Model
          </label>
          <select 
          id="model" 
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
          value={model} 
          onChange={(e) => setModel(e.target.value)}
        >
          {availableModels.map((supportedModel) => (
            <option key={supportedModel.key} value={supportedModel.key}>
              {supportedModel.name} ({supportedModel.provider})
            </option>
          ))}
        </select>
        </div>

        <div>
          <label htmlFor="instructions" className="block text-sm font-medium text-gray-700">
            Instructions
          </label>
          <textarea
            id="instructions"
            rows={10}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            value={instructions}
            onChange={(e) => setInstructions(e.target.value)}
          />
        </div>

        <div className="mt-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">Tools</label>
          <div className="space-y-2">
            {tools.map((tool) => (
              <ToolSelector key={tool.metadata.name} metadata={tool.metadata} isSelected={selectedTools[tool.metadata.name] || false} onToggle={() => handleToolToggle(tool.metadata.name)} />
            ))}
          </div>
        </div>

        <div className="mt-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">Custom Tools</label>
          <div className="space-y-2">
            {customTools.map((tool, index) => {
              const toolName = Object.keys(tool)[0];
              const toolData = tool[toolName];
              return (
                <div key={index} className="flex items-center justify-between py-2">
                  <div className="flex flex-col flex-grow mr-4">
                    <span className="text-sm font-medium text-gray-900">{toolName}</span>
                    <span className="text-xs text-gray-500">{toolData.description}</span>
                  </div>
                  <div className="flex items-center">
                    <button onClick={() => openDialog(index)} className="text-blue-600 bg-blue-100 p-2 rounded-full hover:bg-blue-200 transition-colors duration-200" aria-label="Edit">
                      <Pencil1Icon className="w-5 h-5" />
                    </button>
                    <button onClick={() => handleRemoveCustomTool(index)} className="text-red-600 bg-red-100 p-2 rounded-full hover:bg-red-200 transition-colors duration-200 ml-2" aria-label="Remove">
                      <TrashIcon className="w-5 h-5" />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
          <button
            onClick={() => openDialog()}
            className="mt-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Add Custom Tool
          </button>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Conversation starters</label>
          {starters.map((starter, index) => (
            <div key={index} className="flex items-center mb-2">
              <input
                type="text"
                className="flex-grow border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={starter}
                onChange={(e) => handleStarterChange(index, e.target.value)}
              />
              <button type="button" onClick={() => handleRemoveStarter(index)} className="ml-2 text-red-600 bg-red-100 p-2 rounded-full hover:bg-red-200 transition-colors duration-200" aria-label="Remove">
                <TrashIcon className="w-5 h-5" />
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={handleAddStarter}
            className="mt-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Add Starter
          </button>
        </div>

        <div className="flex justify-end space-x-4">
        {!isNewAgent && (
            <button
              type="button"
              onClick={handleDeleteAgent}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Delete Agent
            </button>
          )}

          <button
            type="button"
            onClick={handleSaveConfiguration}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {isNewAgent ? "Create Agent" : "Update Agent"}
          </button>
        </div>
      </div>

      <Transition appear show={isDialogOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeDialog}>
          <TransitionChild as={React.Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild as={React.Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                <DialogPanel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <DialogTitle as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    {editingToolIndex !== null ? "Edit Custom Tool" : "Add Custom Tool"}
                  </DialogTitle>
                  <div className="mt-2">
                    <div className="mt-4 mb-6">
                      <label htmlFor="toolTemplate" className="block text-sm font-medium text-gray-700 mb-2">
                        Select an existing tool as template
                      </label>
                      <select
                        id="toolTemplate"
                        onChange={(e) => handleSelectExistingTool(e.target.value)}
                        className="block w-full px-3 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      >
                        <option value="">Choose a tool...</option>
                        {tools.map((tool) => (
                          <option key={tool.metadata.name} value={tool.metadata.name}>
                            {tool.metadata.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <textarea
                      value={newCustomTool}
                      onChange={(e) => setNewCustomTool(e.target.value)}
                      placeholder="Enter custom tool JSON here"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      rows={10}
                    />
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mr-2"
                      onClick={handleAddOrUpdateCustomTool}
                    >
                      {editingToolIndex !== null ? "Update" : "Add"}
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={closeDialog}
                    >
                      Cancel
                    </button>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default AgentConfig;
