import React, { useState } from "react";
import { PersonIcon, ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import { IconSpinner } from "../../ui/Icons";
import { useAI } from "../../contexts/AIContext";

export const callAgentSchema = {
  call_agent: {
    parameters: {
      type: "object",
      required: ["message", "agentId"],
      properties: {
        message: {
          type: "string",
          description: "The message to send to the agent",
        },
        agentId: {
          type: "string",
          description: "The ID of the agent to call",
        },
        respondToThreadId: {
          type: "string",
          description: "The ID of an existing thread, returned in a previous call to this tool. If provided, the message will be appended to this thread; otherwise, a new thread will be created.",
        },
      },
    },
    description: "Call another agent on the Aster Agent platform",
  },
};

export const toolMetadata = {
  name: "call_agent",
  displayName: "Call Agent",
  description: "Call another agent on the Aster Agent platform",
};

const CallAgent = ({ invocation }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { agents } = useAI();

  if (!invocation) {
    return null;
  }

  const { args, result } = invocation;

  // Determine if the result is ready by checking if the result object exists and has the necessary properties
  const isReady = result && (result.status === "success" || result.status === "error");
  
  const agentInfo = agents.find((a) => a.id === parseInt(args.agentId));

  return (
    <div className="border rounded-lg overflow-hidden">
      <div className={`flex items-center justify-between p-3 bg-white ${isReady ? "cursor-pointer" : ""}`} onClick={() => isReady && setIsExpanded(!isExpanded)}>
        <div className="flex items-center space-x-2">
          {!isReady ? (
            <IconSpinner className="w-5 h-5 flex-shrink-0" />
          ) : agentInfo && agentInfo.logoUrl ? (
            <div className="w-5 h-5 flex-shrink-0 flex items-center justify-center">
              <img src={agentInfo.logoUrl} alt={agentInfo.name} className="w-full h-full object-cover rounded-full" />
            </div>
          ) : (
            <PersonIcon className="w-5 h-5 flex-shrink-0" />
          )}
          <span className="font-medium">Messaging {agentInfo ? agentInfo.name : `ID: ${args.agentId}`}</span>
        </div>
        <div className="flex items-center">{isReady && (isExpanded ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />)}</div>
      </div>

      {isReady && isExpanded && (
        <div className="p-4 bg-white">
          {result.status === "success" ? (
            <div className="space-y-4">
              <div className="border-b pb-2">
                <div className="font-medium">Message</div>
                <div className="mt-1 text-sm whitespace-pre-wrap">{args.message}</div>
              </div>
              <div>
                <div className="font-medium">Response</div>
                <div className="mt-1 text-sm whitespace-pre-wrap">{JSON.stringify(result.result.content, null, 2)}</div>
              </div>
            </div>
          ) : (
            <div className="text-red-600">Error calling agent: {JSON.stringify(result.result.content, null, 2) || "Unknown error"}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default CallAgent;
