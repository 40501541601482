import React, { useState } from "react";
import { MagnifyingGlassIcon, ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import { IconSpinner } from "../../ui/Icons";

export const searchGoogleSchema = {
  search_google: {
    parameters: {
      type: "object",
      required: ["query"],
      properties: {
        query: {
          type: "string",
          description: "The search query",
        },
      },
    },
    description: "Search Google for a given search query",
  },
};

export const toolMetadata = {
  name: "search_google",
  displayName: "Search Google",
  description: "Search Google for a given search query",
};

const SearchGoogle = ({ invocation }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!invocation) {
    return null;
  }

  const { args, result } = invocation;

  // Determine if the result is ready by checking if the result object exists and has the necessary properties
  const isReady = result && (result.status === "success" || result.status === "error");

 return (
    <div className="border rounded-lg overflow-hidden">
      <div 
        className={`flex items-center justify-between p-3 bg-white ${isReady ? 'cursor-pointer' : ''}`}
        onClick={() => isReady && setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center space-x-2">
          {!isReady ? <IconSpinner className="w-5 h-5" /> : <MagnifyingGlassIcon className="w-5 h-5" />}
          <span className="font-medium">Searching Web for "{args.query}"</span>
        </div>
        <div className="flex items-center">
          {isReady && (
            isExpanded ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />
          )}
        </div>
      </div>
      
      {isReady && isExpanded && (
        <div className="p-4 bg-white">
          {result.status === "success" ? (
            <div className="space-y-4">
              <div className="border-b pb-2">
                <div className="font-medium">Search Results</div>
                <div className="mt-1 text-sm whitespace-pre-wrap">{result.result.content}</div>
              </div>
            </div>
          ) : (
            <div className="text-red-600">Error searching web: {result.message || "Unknown error"}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchGoogle;