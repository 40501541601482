import React, { useState } from "react";
import { PieChartIcon, ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import { IconSpinner } from "../../ui/Icons";


const SPORTS = {
  CFL: "americanfootball_cfl",
  NCAAF: "americanfootball_ncaaf",
  NCAAF_CHAMPIONSHIP_WINNER: "americanfootball_ncaaf_championship_winner",
  NFL: "americanfootball_nfl",
  NFL_SUPER_BOWL_WINNER: "americanfootball_nfl_super_bowl_winner",
  AFL: "aussierules_afl",
  MILB: "baseball_milb",
  MLB: "baseball_mlb",
  MLB_WORLD_SERIES_WINNER: "baseball_mlb_world_series_winner",
  NBA: "basketball_nba",
  NBA_CHAMPIONSHIP_WINNER: "basketball_nba_championship_winner",
  NCAAB_CHAMPIONSHIP_WINNER: "basketball_ncaab_championship_winner",
  WNBA: "basketball_wnba",
  BOXING: "boxing_boxing",
  CPLT20: "cricket_caribbean_premier_league",
  INTERNATIONAL_T20: "cricket_international_t20",
  T20_BLAST: "cricket_t20_blast",
  TEST_MATCH: "cricket_test_match",
  MASTERS: "golf_masters_tournament_winner",
  PGA: "golf_pga_championship_winner",
  NHL: "icehockey_nhl",
  NHL_CHAMPIONSHIP_WINNER: "icehockey_nhl_championship_winner",
  HOCKEYALLSVENSKAN: "icehockey_sweden_allsvenskan",
  SHL: "icehockey_sweden_hockey_league",
  PLL: "lacrosse_pll",
  MMA: "mma_mixed_martial_arts",
  US_PRESIDENTIAL_ELECTION_WINNER: "politics_us_presidential_election_winner",
  NRL: "rugbyleague_nrl",
  ARGENTINA_PRIMERA_DIVISION: "soccer_argentina_primera_division",
  AUSTRIA_BUNDESLIGA: "soccer_austria_bundesliga",
  BELGIUM_FIRST_DIV: "soccer_belgium_first_div",
  BRAZIL_CAMPEONATO: "soccer_brazil_campeonato",
  BRAZIL_SERIE_B: "soccer_brazil_serie_b",
  CHILE_CAMPEONATO: "soccer_chile_campeonato",
  CONMEBOL_COPA_LIBERTADORES: "soccer_conmebol_copa_libertadores",
  DENMARK_SUPERLIGA: "soccer_denmark_superliga",
  EFL_CHAMP: "soccer_efl_champ",
  ENGLAND_LEAGUE1: "soccer_england_league1",
  ENGLAND_LEAGUE2: "soccer_england_league2",
  EPL: "soccer_epl",
  FIFA_WORLD_CUP_WINNER: "soccer_fifa_world_cup_winner",
  FRANCE_LIGUE_ONE: "soccer_france_ligue_one",
  GERMANY_BUNDESLIGA: "soccer_germany_bundesliga",
  GERMANY_BUNDESLIGA2: "soccer_germany_bundesliga2",
  GREECE_SUPER_LEAGUE: "soccer_greece_super_league",
  ITALY_SERIE_A: "soccer_italy_serie_a",
  ITALY_SERIE_B: "soccer_italy_serie_b",
  JAPAN_J_LEAGUE: "soccer_japan_j_league",
  KOREA_KLEAGUE1: "soccer_korea_kleague1",
  LEAGUE_OF_IRELAND: "soccer_league_of_ireland",
  MEXICO_LIGAMX: "soccer_mexico_ligamx",
  NETHERLANDS_EREDIVISIE: "soccer_netherlands_eredivisie",
  NORWAY_ELITESERIEN: "soccer_norway_eliteserien",
  PORTUGAL_PRIMEIRA_LIGA: "soccer_portugal_primeira_liga",
  SPAIN_LA_LIGA: "soccer_spain_la_liga",
  SPAIN_SEGUNDA_DIVISION: "soccer_spain_segunda_division",
  SPL: "soccer_spl",
  SWEDEN_ALLSVENSKAN: "soccer_sweden_allsvenskan",
  SWEDEN_SUPERETTAN: "soccer_sweden_superettan",
  TURKEY_SUPER_LEAGUE: "soccer_turkey_super_league",
  UEFA_CHAMPS_LEAGUE: "soccer_uefa_champs_league",
  UEFA_CHAMPS_LEAGUE_QUALIFICATION: "soccer_uefa_champs_league_qualification",
  UEFA_EUROPA_CONFERENCE_LEAGUE: "soccer_uefa_europa_conference_league",
  UEFA_EUROPA_LEAGUE: "soccer_uefa_europa_league",
  UEFA_NATIONS_LEAGUE: "soccer_uefa_nations_league",
  MLS: "soccer_usa_mls",
  UFC: "mma_mixed_martial_arts",
  PGA: "golf_pga_championship_winner",
  MASTERS: "golf_masters_tournament_winner",
};

// Tool definition and schema
export const getEventsSchema = {
  get_events: {
    parameters: {
      type: "object",
      required: ["sport"],
      properties: {
        sport: {
          type: "string",
          enum: Object.values(SPORTS),
          description: "The sport key to fetch events for",
        },
      },
    },
    description: "Fetch the upcoming events for a specific sport",
  },
};

export const toolMetadata = {
  name: "get_events",
  displayName: "Get Events",
  description: "Fetch the upcoming events for a specific sport",
};

const GetEvents = ({ invocation }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!invocation || !invocation.args) {
    return null;
  }

  const { result, args } = invocation;

  const isReady = result && result.status;

  return (
    <div className="border rounded-lg overflow-hidden">
      <div className={`flex items-center justify-between p-3 bg-white ${isReady ? "cursor-pointer" : ""}`} onClick={() => isReady && setIsExpanded(!isExpanded)}>
        <div className="flex items-center space-x-2">
          {!isReady ? <IconSpinner className="w-5 h-5" /> : <PieChartIcon className="w-5 h-5" />}
          <span className="font-medium">Fetching Events: {args.sport}</span>
        </div>
        <div className="flex items-center">{isReady && (isExpanded ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />)}</div>
      </div>

      {isReady && isExpanded && (
        <div className="p-4 bg-white">
          {result.status === "success" ? (
            <div className="space-y-4">
              <div>
                <strong>Sport:</strong> {args.sport}
              </div>
              {args.eventIds && (
                <div>
                  <strong>Event IDs:</strong> {args.eventIds}
                </div>
              )}
              <div className="border-b pb-2">
                <div className="font-medium">Events Data</div>
                <div className="mt-1 text-sm whitespace-pre-wrap">
                  {JSON.stringify(result.result, null, 2)}
                </div>
              </div>
            </div>
          ) : (
            <div className="text-red-600">Error fetching events: {result.error || "Unknown error"}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default GetEvents;