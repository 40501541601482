import React from "react";
import { z } from 'zod';

export const SupportedIntegrations = [
  {
    name: "Snowflake",
    key: "snowflake",
    logoUrl: "https://cdn.icon-icons.com/icons2/2699/PNG/512/snowflake_logo_icon_167979.png",
    description: "Enable your Agents to query and analyze data in Snowflake.",
    schema: z.object({
      account: z.string().min(1, "Account identifier is required").describe("Your account identifier, excluding the snowflakecomputing.com suffix"),
      user: z.string().min(1, "Username is required").describe("Login name for the user"),
      password: z.string().min(8, "Password must be at least 8 characters").describe("Password for the user"),
      warehouse: z.string().min(1, "Warehouse name is required").describe("Name of the default warehouse to use"),
      database: z.string().optional().describe("Name of the default database to use (optional)"),
      schema: z.string().optional().describe("Name of the default schema to use for the database (optional)"),
      role: z.string().optional().describe("Name of the default role to use (optional)"),
    }),
  },
  {
    name: "Postgres",
    key: "postgres",
    logoUrl: "/logos/postgres.png",
    description: "Enable your Agents to query and analyze data in Postgres.",
    schema: z.object({
      host: z.string().min(1, "Host is required").describe("Database server hostname or IP address"),
      port: z.number().int().positive().default(5432).describe("Port number (default is 5432)"),
      database: z.string().min(1, "Database name is required").describe("Name of the database to connect to"),
      user: z.string().min(1, "Username is required").describe("Username for authentication"),
      password: z.string().min(1, "Password is required").describe("Password for authentication"),
      sslmode: z.enum(['disable', 'allow', 'prefer', 'require', 'verify-ca', 'verify-full'])
      .optional()
      .describe("SSL mode (optional): disable, allow, prefer, require, verify-ca, or verify-full"),
    }),
  },
  {
    name: "Salesforce",
    key: "salesforce",
    logoUrl: "/logos/salesforce.png",
    description: "Enable your Agents to interact with Salesforce for CRM and sales automation.",
    comingSoon: true,
    schema: z.object({
      apiToken: z.string().min(1, "API token is required"),
    }),
  },
  {
    name: "Slack",
    key: "slack",
    logoUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Slack_icon_2019.svg/2048px-Slack_icon_2019.svg.png",
    description: "Enable your Agents to send and receive messages from Slack.",
    comingSoon: true,
    schema: z.object({
      apiToken: z.string().min(1, "API token is required"),
    }),
  },
  {
    name: "Jira",
    key: "jira",
    logoUrl: "/logos/jira.png",
    description: "Create and manage Jira issues and projects.",
    comingSoon: true,
    schema: z.object({
      apiToken: z.string().min(1, "API token is required"),
    }),
  },
  {
    name: "GitHub",
    key: "github",
    logoUrl: "/logos/github.png",
    description: "Enable your Agents to interact with GitHub for version control and collaboration.",
    comingSoon: true,
    schema: z.object({
      personalAccessToken: z.string().min(1, "Personal access token is required"),
    }),
  },
  // Add more integrations as needed
];