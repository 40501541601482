import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { HomeIcon, DashboardIcon, ChatBubbleIcon, GearIcon, MixIcon, BarChartIcon, QuestionMarkCircledIcon, RowsIcon, PersonIcon, DoubleArrowRightIcon, DoubleArrowLeftIcon, ChevronRightIcon, ChevronDownIcon } from "@radix-ui/react-icons";
import { useConversation } from "../contexts/ConversationContext";
import { formatDistanceToNow } from "date-fns";

const ConversationItem = ({ conversation, depth = 0 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const hasSubConversations = conversation.subConversations && conversation.subConversations.length > 0;

  return (
    <>
      <div className={`flex items-center px-2 py-2 text-sm text-gray-600 hover:bg-gray-200 hover:text-gray-900 rounded-md transition-all duration-200 ${depth > 0 ? `ml-${depth * 4}` : ""}`}>
        <Link key={conversation.id} to={`/chat/${conversation.id}`} className="flex items-center flex-grow min-w-0">
          <div className="flex-shrink-0 w-6 h-6 mr-3">
            {conversation.agentLogoUrl ? <img src={conversation.agentLogoUrl} alt={conversation.agentName} className="w-full h-full rounded-lg" /> : <PersonIcon className="w-full h-full text-gray-400" />}
          </div>
          <div className="flex-grow min-w-0">
            <div className="truncate">{conversation.agentName || `Chat ${conversation.id.slice(0, 8)}`}</div>
            {depth === 0 && <div className="text-xs text-gray-400 mt-0.5">{formatDistanceToNow(new Date(conversation.updatedAt), { addSuffix: true })}</div>}
          </div>
        </Link>
        {hasSubConversations && (
          <button onClick={() => setIsExpanded(!isExpanded)} className="ml-2 p-1 rounded-full hover:bg-gray-300">
            {isExpanded ? <ChevronDownIcon className="w-4 h-4" /> : <ChevronRightIcon className="w-4 h-4" />}
          </button>
        )}
      </div>
      {isExpanded && hasSubConversations && conversation.subConversations.map((subConversation) => <ConversationItem key={subConversation.id} conversation={subConversation} depth={depth + 1} />)}
    </>
  );
};

const Sidebar = ({ isExpanded, toggleSidebar }) => {
  const { conversations, isLoadingConversations } = useConversation();

  const NavItem = ({ icon: Icon, name, href }) => (
    <NavLink
      to={href}
      className={({ isActive }) => `
      flex items-center px-2 py-2 text-md font-medium transition-all duration-200 rounded-lg
      ${isActive ? "bg-gray-300 text-gray-900" : "text-gray-700 hover:bg-gray-200 hover:text-gray-900"}
      ${isExpanded ? "" : "justify-center"}
    `}
      title={isExpanded ? "" : name}
    >
      <Icon className={`flex-shrink-0 w-5 h-5 ${isExpanded ? "mr-4" : ""}`} />
      {isExpanded && name}
    </NavLink>
  );

  return (
    <div
      className={`flex flex-col flex-shrink-0 transition-all duration-300 ease-in-out bg-gray-100 border-r border-gray-200 shadow-xs
      ${isExpanded ? "w-64" : "w-16"}`}
    >
      <div className="flex flex-col h-full overflow-y-auto">
        <div className={`flex-grow py-2 ${isExpanded ? "px-4" : "px-2"} space-y-4`}>
          <nav className="flex-1 space-y-1">
            <button
              onClick={toggleSidebar}
              className={`flex items-center w-full px-2 py-2 text-md font-medium transition-all duration-200 text-gray-900 rounded-lg hover:bg-gray-200 group
                          ${isExpanded ? "" : "justify-center"}`}
              title={isExpanded ? "Collapse Sidebar" : "Expand Sidebar"}
            >
              {isExpanded ? <DoubleArrowLeftIcon className="flex-shrink-0 w-5 h-5" /> : <DoubleArrowRightIcon className="flex-shrink-0 w-5 h-5" />}
              {isExpanded && <span className="ml-4">Collapse</span>}
            </button>

            <NavItem icon={DashboardIcon} name="Dashboard" href="/dashboard" />
            <NavItem icon={GearIcon} name="Control Hub" href="/control-hub" />
            <NavItem icon={ChatBubbleIcon} name="New Chat" href="/chat" />

            {isExpanded && (
              <div className="mt-2 pt-2 space-y-1 border-t border-gray-200">
                {isLoadingConversations ? <div className="text-sm text-gray-500">Loading conversations...</div> : conversations.map((conversation) => <ConversationItem key={conversation.id} conversation={conversation} />)}
              </div>
            )}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
