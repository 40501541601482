"use client";

import { useEffect, useRef, useState } from "react";
import Textarea from "react-textarea-autosize";
import { ArrowUpCircleIcon, PaperClipIcon } from "@heroicons/react/24/outline";
import { IconSpinner } from "../ui/Icons.js";
import { Button } from "../ui/Button.js";
import { Tooltip, TooltipContent, TooltipTrigger, TooltipProvider } from "../ui/Tooltip.js";
import { useEnterSubmit } from "../hooks/use-enter-submit.js";

export function ChatInputForm({ selectedAgent, input, handleInputChange, handleFormSubmit, isLoading, files, setFiles }) {
  const inputRef = useRef(null);
  const fileInputRef = useRef(null);
  const { formRef, onKeyDown } = useEnterSubmit();
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleFileChange = (event) => {
    setFiles(event.target.files);
  };

  const handlePaste = async (event) => {
    const clipboardItems = event.clipboardData.items;
    let filesToAdd = [];

    for (let i = 0; i < clipboardItems.length; i++) {
      const item = clipboardItems[i];
      if (item.kind === "file") {
        const file = item.getAsFile();
        if (file && file.type.startsWith("image/")) {
          const base64 = await convertToBase64(file);
          filesToAdd.push({
            name: file.name,
            contentType: file.type,
            url: base64,
          });
        }
      }
    }

    if (filesToAdd.length > 0) {
      event.preventDefault();
      setFiles((prevFiles) => [...prevFiles, ...filesToAdd]);
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  return (
    <TooltipProvider>
      <form
        ref={formRef}
        onSubmit={(event) => {
          event.preventDefault();
          if (window.innerWidth < 600) {
            event.target.blur();
          }
          handleFormSubmit(event);
        }}
        className="relative flex max-h-60 w-full grow flex-col overflow-hidden bg-background px-8 sm:rounded-md sm:border sm:px-12"
      >
        <Textarea
          ref={inputRef}
          className="min-h-[60px] w-full resize-none bg-transparent pl-4 pr-10 py-[1.3rem] focus-within:outline-none sm:text-sm border-none focus:ring-2 focus:ring-offset-2 focus:ring-transparent"
          placeholder={`Message ${selectedAgent ? selectedAgent.name : "AI"}...`}
          autoFocus
          spellCheck={true}
          autoComplete="on"
          autoCorrect="on"
          value={input}
          onChange={handleInputChange}
          onKeyDown={onKeyDown}
          onPaste={handlePaste}
        />
        <div className="absolute right-0 top-[13px] sm:right-2">
          <Tooltip>
            <input
              type="file"
              multiple ref={fileInputRef}
              onChange={handleFileChange}
              className="hidden"
              accept="image/*,.txt,.doc,.docx,.csv,.json,.xml,.md"
            />
            <TooltipTrigger asChild>
              <Button
                onClick={(event) => {
                  event.preventDefault();
                  fileInputRef.current.click();
                }}
                disabled={isLoading}
                variant="outline"
                size="icon"
                className="rounded-full bg-background p-0 mr-1"
              >
                <span className="relative">
                  {isUploading ? (
                    <IconSpinner className="w-6 h-6 animate-spin" />
                  ) : (
                    <>
                      <PaperClipIcon className="w-6 h-6" />
                      {files && files.length > 0 && <span className="absolute -top-3 -right-3 flex h-6 w-6 items-center justify-center rounded-full bg-blue-500 text-xs text-white">{files.length}</span>}
                    </>
                  )}
                </span>
              </Button>
            </TooltipTrigger>
            <TooltipContent>Attach files</TooltipContent>
          </Tooltip>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button type="submit" disabled={isLoading || !input.trim()} variant="outline" size="icon" className="rounded-full bg-background p-0">
                {isLoading ? (
                  <IconSpinner className="w-6 h-6 animate-spin" />
                ) : (
                  <>
                    <ArrowUpCircleIcon className="w-6 h-6" />
                    <span className="sr-only">Send</span>
                  </>
                )}
              </Button>
            </TooltipTrigger>
            <TooltipContent>Send message</TooltipContent>
          </Tooltip>
        </div>
      </form>
    </TooltipProvider>
  );
}
