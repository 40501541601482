import React, { createContext, useContext, useState, useCallback, useEffect, useMemo } from 'react';
import useSWR from 'swr';
import { usePostHog } from 'posthog-js/react';
import { useAuth } from '@clerk/clerk-react';
import { useAuthenticatedSWR } from '../hooks/use-authenticated-swr';
import { ModelProviders } from '../components/ControlHub/ModelProviders';
import { SupportedIntegrations } from '../components/ControlHub/SupportedIntegrations';

const AIContext = createContext();

export const AIContextProvider = ({ children }) => {
    const { getToken, isLoaded, isSignedIn, userId } = useAuth();
    const posthog = usePostHog();
    const [state, setState] = useState({
        userInfo: null,
        agents: [],
        models: [],
        integrations: [],
        error: null,
        isAdmin: false
    });

    // SWR hooks for fetching user data and agents
    const { data: userData, error: userError, mutate: mutateUser } = useAuthenticatedSWR('/api/getUser');
    const { data: agentsData, error: agentsError, mutate: mutateAgents } = useAuthenticatedSWR('/api/agents/getAgents');

    // Update state when SWR data changes
    useEffect(() => {
        if (userData) {
            const updatedModels = ModelProviders.map((model) => ({
                ...model,
                isConnected: !!userData.organizations[0].connections[model.key],
            }));

            const updatedIntegrations = SupportedIntegrations.map((integration) => ({
                ...integration,
                isConnected: !!userData.organizations[0].connections[integration.key],
            }));

            const isAdmin = userData.organizations[0]?.role === 'org:admin';

            setState(prev => ({
                ...prev,
                userInfo: userData,
                models: updatedModels,
                integrations: updatedIntegrations,
                isAdmin: isAdmin
            }));

            posthog?.identify(userId, {
                email: userData.email,
                firstName: userData.firstName,
                lastName: userData.lastName,
            });
        }
    }, [userData, userId, posthog]);

    useEffect(() => {
        if (agentsData) {
            setState(prev => ({
                ...prev,
                agents: agentsData,
            }));
        }
    }, [agentsData]);

    // Combine errors
    useEffect(() => {
        setState(prev => ({
            ...prev,
            error: userError || agentsError || null,
        }));
    }, [userError, agentsError]);

    const contextValue = useMemo(() => ({
        ...state,
        isLoadingUser: !userData && !userError,
        isLoadingAgents: !agentsData && !agentsError,
        userId,
        fetchUserData: mutateUser,
        fetchAgents: mutateAgents
    }), [state, userData, userError, agentsData, agentsError, userId, mutateUser, mutateAgents]);

    console.log('Rendering AIContextProvider. userInfo:', state.userInfo, 'userId:', userId, 'agents:', state.agents);

    return (
        <AIContext.Provider value={contextValue}>
            {children}
        </AIContext.Provider>
    );
};

export const useAI = () => {
    const context = useContext(AIContext);
    if (context === undefined) {
        throw new Error('useAI must be used within an AIContextProvider');
    }
    return context;
};