import { z } from "zod";

export const ModelProviders = [
  {
    name: "OpenAI",
    key: "openai",
    logoUrl: "/logos/openai/PNGs/openai-logomark.png",
    description: "Connect to OpenAI's powerful language models like GPT-3.5 and GPT-4.",
    isConnected: false,
    schema: z.object({
      OPENAI_API_KEY: z.string().min(1, "API key is required").describe("Your OpenAI API key"),
    }),
  },
  {
    name: "Azure OpenAI",
    key: "azure_openai",
    logoUrl: "/logos/azure/azure.png",
    description: "Access OpenAI models through Microsoft Azure's cloud platform.",
    isConnected: false,
    schema: z.object({
      RESOURCE_NAME: z.string().min(1, "Resource name is required").describe("Your Azure resource name"),
      API_KEY: z.string().min(1, "API key is required").describe("Your Azure OpenAI API key"),
      DEPLOYMENT_NAME: z.string().min(1, "Deployment name is required").describe("Name of your model deployment"),
    }),
  },
  {
    name: "Anthropic",
    key: "anthropic",
    logoUrl: "/logos/anthropic/anthropic.png",
    description: "Connect to Anthropic's Claude and other AI models.",
    isConnected: false,
    schema: z.object({
      ANTHROPIC_API_KEY: z.string().min(1, "API key is required").describe("Your Anthropic API key"),
    }),
  },
  {
    name: "together.ai",
    key: "together_ai",
    logoUrl: "/logos/togetherai.png",
    description: "Connect to together.ai models using their OpenAI-compatible API.",
    isConnected: false,
    schema: z.object({
      apiKey: z.string().min(1, "API key is required").describe("Your together.ai API key"),
      baseUrl: z.string().url("Must be a valid URL").default("https://api.together.xyz").describe("Base URL for the API (default: https://api.together.xyz)"),
      models: z.array(z.string()).min(1, "At least one model must be specified").describe("List of model strings (e.g., 'meta-llama/Meta-Llama-3.1-70B-Instruct-Turbo')"),
    }),
  },
  {
    name: "AWS Bedrock",
    key: "aws_bedrock",
    logoUrl: "/logos/aws.png",
    description: "Access various AI models through Amazon's Bedrock service.",
    isConnected: false,
    schema: z.object({
      accessKeyId: z.string().min(1, "Access Key ID is required").describe("Your AWS Access Key ID"),
      secretAccessKey: z.string().min(1, "Secret Access Key is required").describe("Your AWS Secret Access Key"),
      region: z.string().min(1, "Region is required").describe("AWS region (e.g., us-east-1)"),
    }),
  },
];
