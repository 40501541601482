import React from "react";
import { PersonIcon, BackpackIcon, SewingPinIcon, TimerIcon, MobileIcon, StarIcon, PlusIcon } from "@radix-ui/react-icons";
import { Link } from "react-router-dom";
import { useAI } from "../../contexts/AIContext";
import { IconSpinner } from "../../ui/Icons";

const Agents = () => {
  const { agents, isLoadingAgents } = useAI();

  if (isLoadingAgents) {
    return (
      <div className="flex-grow flex justify-center items-center h-full">
        <IconSpinner className="w-10 h-10" />
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 xl:grid-cols-3 sm:gap-6">
        <div className="relative overflow-hidden transition-all duration-200 bg-blue-100 rounded-xl hover:bg-blue-200">
          <div className="p-6">
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0 w-10 h-10 text-gray-600">
                <PlusIcon className="w-full h-full" />
              </div>
              <div className="flex-grow">
                <h3 className="text-sm font-bold text-gray-900 sm:text-base">
                  <Link to="/control-hub/agents/new" className="hover:underline">
                    New Agent
                    <span className="absolute inset-0" aria-hidden="true"></span>
                  </Link>
                </h3>
                <p className="mt-1 text-sm text-gray-500">Create a new agent</p>
              </div>
            </div>
          </div>
        </div>
        {agents.map((agent) => (
          <div key={agent.id} className="relative overflow-hidden transition-all duration-200 bg-gray-100 rounded-xl hover:bg-gray-200">
            <div className="p-6">
              <div className="flex items-center space-x-4">
                <div className="flex-shrink-0 w-10 h-10 text-gray-600">
                  <span className="text-2xl font-bold">{agent.logoUrl ? <img src={agent.logoUrl} alt={agent.name} className="w-full h-full" /> : agent.name[0]}</span>
                </div>
                <div className="flex-grow">
                  <h3 className="text-sm font-bold text-gray-900 sm:text-base">
                    <Link to={`/control-hub/agents/${agent.id}`} className="hover:underline">
                      {agent.name}
                      <span className="absolute inset-0" aria-hidden="true"></span>
                    </Link>
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    {agent.tools && typeof agent.tools === "object"
                      ? (() => {
                          const toolCount = Object.keys(agent.tools).length;
                          return toolCount === 1 ? "1 Tool" : `${toolCount} Tools`;
                        })()
                      : "No tools"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Agents;
