import React, { useState, useEffect } from "react";
import { Button, Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAI } from "../../contexts/AIContext";
import { IconSpinner } from "../../ui/Icons";
import { useAuthenticatedFetch } from "../../hooks/use-authenticated-fetch";
import { toast } from "sonner";

import { SupportedIntegrations } from "./SupportedIntegrations";
import zodSchemaToFormFields from "../../lib/zodform";

const Integrations = () => {
  const { integrations, userInfo, fetchUserData, isLoadingUser, isLoadingAgents } = useAI();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const authenticatedFetch = useAuthenticatedFetch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(selectedIntegration?.schema),
  });

  const openModal = (integration) => {
    setSelectedIntegration(integration);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedIntegration(null);
  };

  const onSubmit = async (data) => {
  try {
    const response = await authenticatedFetch("/api/updateConnections", {
      method: "POST",
      body: { [selectedIntegration.key]: data },
    });

    fetchUserData();
    console.log("Connection updated successfully:", response);
    toast.success("Connection updated successfully");
  } catch (error) {
    console.error("Error updating connection:", error);
    toast.error("Failed to update connection. Please try again.");
  }
  closeModal();
};

  if (isLoadingUser) {
    return (
      <div className="flex-grow flex justify-center items-center h-full">
        <IconSpinner className="w-10 h-10" />
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {integrations.map((integration) => (
          <div key={integration.key} className="relative overflow-hidden bg-gray-100 rounded-xl flex flex-col h-full">
            <div className="p-6 flex-grow">
              <div className="flex items-center space-x-4">
                <div className="flex-shrink-0 w-14 h-14">
                  <img src={integration.logoUrl} alt={`${integration.name} logo`} className="w-full h-full object-contain" />
                </div>
                <div className="flex-grow">
                  <h3 className="text-lg font-bold text-gray-900">{integration.name}</h3>
                  {integration.isConnected && !integration.comingSoon && (
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                      <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-green-400" fill="currentColor" viewBox="0 0 8 8">
                        <circle cx="4" cy="4" r="3" />
                      </svg>
                      Connected
                    </span>
                  )}
                  <p className="mt-1 text-sm text-gray-500">{integration.description}</p>
                </div>
              </div>
            </div>
            <div className="p-6 pt-0">
              <Button
                onClick={() => openModal(integration)}
                disabled={integration.comingSoon}
                className={`w-full px-4 py-2 text-sm font-medium rounded-md ${
                  integration.comingSoon ? "text-gray-400 bg-gray-200 cursor-not-allowed" : integration.isConnected ? "text-red-600 bg-red-100 hover:bg-red-200" : "text-white bg-blue-600 hover:bg-blue-700"
                }`}
              >
                {integration.comingSoon ? "Coming Soon" : integration.isConnected ? "Disconnect" : "Connect"}
              </Button>
            </div>
          </div>
        ))}
      </div>
      <Dialog open={isOpen} onClose={closeModal} as="div" className="relative z-10">
        <div className="fixed inset-0 bg-black bg-opacity-25" />
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <DialogPanel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <DialogTitle as="h3" className="text-lg font-medium leading-6 text-gray-900">
                Connect {selectedIntegration?.name}
              </DialogTitle>
              <form onSubmit={handleSubmit(onSubmit)}>
                {selectedIntegration && zodSchemaToFormFields(selectedIntegration.schema, register, errors)}
                <button
                  type="submit"
                  className="mt-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                >
                  Submit
                </button>
              </form>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Integrations;
