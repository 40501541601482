import React, { useState } from "react";
import { MagnifyingGlassIcon, ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import { IconSpinner } from "../../ui/Icons";

// Tool definition and schema
export const semanticSearchPGSchema = {
  semantic_search_pg: {
    parameters: {
      type: "object",
      required: ["query", "table_name"],
      properties: {
        query: {
          type: "string",
          description: "Describe in detail the type of information you are looking for",
        },
        table_name: {
          type: "string",
          description: "The name of the table to search in",
        },
        top_k: {
          type: "integer",
          description: "The number of top results to return (default is 5)",
          default: 5,
        },
      },
    },
    description: "Search Postgres embeddings for a given query in a specified table",
  },
};

export const toolMetadata = {
  name: "semantic_search_pg",
  displayName: "Semantic Search Postgres",
  description: "Search for a string in Postgres. Uses semantic search on the 'embedding' and 'content' columns in the table.",
};

const SemanticSearchPG = ({ invocation }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!invocation || !invocation.args) {
    return null;
  }

  const { result, args } = invocation;
  const isReady = result && result.status;

  return (
    <div className="border rounded-lg overflow-hidden">
      <div 
        className={`flex items-center justify-between p-3 bg-white ${isReady ? 'cursor-pointer' : ''}`}
        onClick={() => isReady && setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center space-x-2">
          {!isReady ? <IconSpinner className="w-5 h-5" /> : <MagnifyingGlassIcon className="w-5 h-5" />}
          <span className="font-medium">Searching {args.table_name} for "{args.query}"</span>
        </div>
        <div className="flex items-center">
          {isReady && (
            isExpanded ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />
          )}
        </div>
      </div>
      
      {isReady && isExpanded && (
        <div className="p-4 bg-white">
          {result.status === "success" ? (
            <div className="space-y-4">
              {result.result?.content?.map((item, index) => (
                <div key={index} className="border-b pb-2 last:border-b-0">
                  <div className="font-medium">{item.title || `Result ${index + 1}`}</div>
                  <div className="mt-1 text-sm whitespace-pre-wrap">{item.content}</div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-red-600">Error searching Postgres: {result.message || "Unknown error"}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default SemanticSearchPG;