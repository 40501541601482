import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import RootLayout from './layouts/root-layout';
import AppLayout from './layouts/app-layout';
import Home from './routes/Home';
import Chat from './routes/Chat';
import Dashboard from './routes/Dashboard';
import ContactPage from './routes/Contact';
import SignInPage from './routes/SignIn';
import SignUpPage from './routes/SignUp';
import ControlHub from './components/ControlHub';
import ErrorBoundary from './components/GenericError';

import './App.css';

import { PostHogProvider } from 'posthog-js/react';

const options = {
  api_host: 'https://us.i.posthog.com'
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />
  },
  {
    element: <RootLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      { path: "/contact", element: <ContactPage /> },
      { path: "/sign-in/*", element: <SignInPage /> },
      { path: "/sign-up/*", element: <SignUpPage /> },
      {
        element: <AppLayout />,
        children: [
          { path: "/dashboard", element: <Dashboard /> },
          { path: "/chat", element: <Chat /> },
          { path: "/chat/:threadId", element: <Chat /> },
          { path: "/control-hub/*", element: <ControlHub /> }
        ]
      }
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey= "phc_DgHnmeT2irNHGBs1I4nZNK5P9pIksCiPf4PG7bjR83x"
      options={options}
    >
      <RouterProvider router={router} />
    </PostHogProvider>
  </React.StrictMode>
);