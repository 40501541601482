import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth, useOrganization, OrganizationList, UserButton } from "@clerk/clerk-react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { useAI } from "../contexts/AIContext";
import { IconSpinner } from "../ui/Icons";

export default function AppLayout() {
  const { userId, isLoaded } = useAuth();
  const { organization } = useOrganization();
  const { isLoadingUser, isLoadingAgents } = useAI();
  const navigate = useNavigate();
  const [sidebarExpanded, setSidebarExpanded] = useState(() => {
    // Check local storage for user preference
    const savedState = localStorage.getItem("sidebarExpanded");
    if (savedState !== null) {
      return JSON.parse(savedState);
    }
    // Default to expanded on larger screens
    return window.innerWidth > 768;
  });

  useEffect(() => {
    // Save user preference to local storage
    localStorage.setItem("sidebarExpanded", JSON.stringify(sidebarExpanded));
  }, [sidebarExpanded]);

  useEffect(() => {
    if (isLoaded && !userId) {
      navigate("/sign-in");
    }
  }, [isLoaded, userId, navigate]);

  const toggleSidebar = () => {
    setSidebarExpanded((prev) => !prev);
  };

  if (isLoadingUser || isLoadingAgents) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="text-center">
          <IconSpinner className="mx-auto mt-4 h-8 w-8 animate-spin text-primary" />
          <img src="/aster.png" alt="Loading" className="mx-auto mt-4 h-16" />
        </div>
      </div>
    );
  }

  if (!userId) {
    navigate("/sign-in");
    return null;
  }

  if (!organization) {
    return (
      <div className="flex flex-col h-screen bg-white">
        <header className="bg-white border-b">
          <div className="px-4 mx-auto">
            <div className="flex items-center justify-between h-16">
              <div className="flex items-center flex-shrink-0">
                <img className="w-auto h-8" src="/icon.png" alt="Aster" />
              </div>
              <div className="flex items-center justify-end ml-auto">
                <UserButton />
              </div>
            </div>
          </div>
        </header>
        <div className="flex-grow flex items-center justify-center">
          <div className="max-w-md w-full space-y-8 p-8 text-center">
            <img src="/aster.png" alt="Aster" className="mx-auto h-12" />
            <h2 className="mt-6 text-3xl font-bold text-gray-900">Welcome to Aster</h2>
            <p className="mt-2 text-sm text-gray-600">Please select an organization or create a new one to continue</p>
            <OrganizationList hidePersonal={true} className="mt-8" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-screen">
      <Sidebar isExpanded={sidebarExpanded} toggleSidebar={toggleSidebar} />
      <div className="flex flex-col flex-grow overflow-hidden">
        <Header toggleSidebar={toggleSidebar} />
        <main className="flex-grow overflow-y-auto transition-all duration-300 ease-in-out">
          <Outlet />
        </main>
      </div>
    </div>
  );
}
