import React, { useState, useEffect } from "react";
import { Button, Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAI } from "../../contexts/AIContext";
import { IconSpinner } from "../../ui/Icons";
import { useAuthenticatedFetch } from "../../hooks/use-authenticated-fetch";
import { toast } from "sonner";

const Models = () => {
  const { models, userInfo, fetchUserData, isLoadingUser, isLoadingAgents } = useAI();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);
  const authenticatedFetch = useAuthenticatedFetch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(selectedModel?.schema),
  });

  const openModal = (model) => {
    setSelectedModel(model);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedModel(null);
  };

  const onSubmit = async (data) => {
    try {
      const response = await authenticatedFetch("/api/updateConnections", {
        method: "POST",
        body: { [selectedModel.key]: data },
      });

      fetchUserData();
      console.log("Connection updated successfully:", response);
      toast.success("Connection updated successfully");
    } catch (error) {
      console.error("Error updating connection:", error);
      toast.error("Failed to update connection. Please try again.");
    }
    closeModal();
  };

  if (isLoadingUser) {
    return (
      <div className="flex-grow flex justify-center items-center h-full">
        <IconSpinner className="w-10 h-10" />
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 gap-5">
        {models.map((model) => (
          <div key={model.key} className="relative overflow-hidden bg-gray-100 rounded-xl">
            <div className="p-6 flex items-center">
              <div className="flex-shrink-0 w-16 h-16 mr-4">
                <img src={model.logoUrl} alt={`${model.name} logo`} className="w-full h-full object-contain" />
              </div>
              <div className="flex-grow">
                <h3 className="text-lg font-bold text-gray-900">{model.name}</h3>
                {model.isConnected && (
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                    <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-green-400" fill="currentColor" viewBox="0 0 8 8">
                      <circle cx="4" cy="4" r="3" />
                    </svg>
                    Connected
                  </span>
                )}
                <p className="mt-1 text-sm text-gray-500">{model.description}</p>
              </div>
              <div className="flex-shrink-0 ml-4">
                <Button onClick={() => openModal(model)} className={`px-4 py-2 text-sm font-medium rounded-md ${model.isConnected ? "text-red-600 bg-red-100 hover:bg-red-200" : "text-white bg-blue-600 hover:bg-blue-700"}`}>
                  {model.isConnected ? "Disconnect" : "Connect"}
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Dialog open={isOpen} onClose={closeModal} as="div" className="relative z-10">
        <div className="fixed inset-0 bg-black bg-opacity-25" />
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <DialogPanel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <DialogTitle as="h3" className="text-lg font-medium leading-6 text-gray-900">
                Connect {selectedModel?.name}
              </DialogTitle>
              <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
                {selectedModel &&
                  Object.entries(selectedModel.schema.shape).map(([key, field]) => (
                    <div key={key} className="mb-4">
                      <label htmlFor={key} className="block text-sm font-medium text-gray-700">
                        {key}
                      </label>
                      <input
                        {...register(key)}
                        type={key.toLowerCase().includes("password") ? "password" : "text"}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      />
                      {field.description && <p className="mt-1 text-sm text-gray-500">{field.description}</p>}
                      {errors[key] && <p className="mt-1 text-sm text-red-600">{errors[key].message}</p>}
                    </div>
                  ))}
                <Button
                  type="submit"
                  className="w-full inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                >
                  Save Connection
                </Button>
              </form>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Models;
