import { z } from "zod";
import React from "react";

const zodSchemaToFormFields = (schema, register, errors, parentKey = "") => {
    if (schema instanceof z.ZodObject) {
      return Object.entries(schema.shape).map(([key, field]) => 
        renderField(key, field, register, errors, parentKey)
      );
    }
    return [renderField("", schema, register, errors, parentKey)];
  };
  
  const renderField = (key, field, register, errors, parentKey = "") => {
    const fullKey = parentKey ? `${parentKey}.${key}` : key;
    const fieldInfo = getFieldInfo(field);
  
    let input = renderInputByType(fullKey, fieldInfo, register);
  
    return (
        <div key={fullKey} className="mb-4">
          <label htmlFor={fullKey} className="block text-sm font-medium text-gray-700">
            {fieldInfo.description || key}
          </label>
          {input}
          {renderFieldMeta(fieldInfo)}
          {errors[fullKey] && <p className="mt-1 text-sm text-red-600">{errors[fullKey].message}</p>}
        </div>
      );
  };
  
  const getFieldInfo = (field) => {
    let fieldSchema = field;
    let isOptional = false;
    let hasDefault = false;
    let defaultValue;
  
    while (fieldSchema instanceof z.ZodEffects || fieldSchema instanceof z.ZodOptional || fieldSchema instanceof z.ZodDefault) {
      if (fieldSchema instanceof z.ZodOptional) {
        isOptional = true;
        fieldSchema = fieldSchema.unwrap();
      } else if (fieldSchema instanceof z.ZodDefault) {
        hasDefault = true;
        defaultValue = fieldSchema._def.defaultValue();
        fieldSchema = fieldSchema._def.innerType;
      } else if (fieldSchema instanceof z.ZodEffects) {
        fieldSchema = fieldSchema.innerType();
      }
    }
  
    return {
      schema: fieldSchema,
      isOptional,
      hasDefault,
      defaultValue,
      description: field.description,
    };
  };
  
  const renderInputByType = (key, fieldInfo, register) => {
    const { schema, hasDefault, defaultValue } = fieldInfo;
    const baseInputClass = "mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500";
  
    const registerOptions = hasDefault ? { defaultValue } : {};
  
    if (schema instanceof z.ZodString) return renderStringField(key, schema, register, baseInputClass, registerOptions);
    if (schema instanceof z.ZodNumber || schema instanceof z.ZodBigInt) return renderNumberField(key, schema, register, baseInputClass, registerOptions);
    if (schema instanceof z.ZodBoolean) return renderBooleanField(key, schema, register, registerOptions);
    if (schema instanceof z.ZodEnum) return renderEnumField(key, schema, register, baseInputClass, registerOptions);
    if (schema instanceof z.ZodDate) return renderDateField(key, schema, register, baseInputClass, registerOptions);
    if (schema instanceof z.ZodLiteral) return renderLiteralField(key, schema, baseInputClass);
    if (schema instanceof z.ZodObject) return renderObjectField(key, schema, register);
    if (schema instanceof z.ZodArray) return renderArrayField(key, schema, register);
  
    return <p className="mt-1 text-sm text-red-600">Unsupported field type</p>;
  };
  
  const renderStringField = (key, schema, register, baseInputClass, registerOptions) => {
    const inputType = schema._def.checks.some((check) => check.kind === "email") ? "email" : 
                      schema._def.checks.some((check) => check.kind === "url") ? "url" : 
                      key.toLowerCase().includes("password") ? "password" : "text";
    return <input id={key} {...register(key, registerOptions)} type={inputType} className={baseInputClass} />;
  };
  
  const renderNumberField = (key, schema, register, baseInputClass, registerOptions) => {
    return <input id={key} {...register(key, { 
      ...registerOptions,
      valueAsNumber: true,
      setValueAs: (v) => v === "" ? undefined : Number(v)
    })} type="number" className={baseInputClass} />;
  };
  
  const renderBooleanField = (key, schema, register, registerOptions) => {
    return (
      <div className="mt-1">
        <input id={key} {...register(key, registerOptions)} type="checkbox" className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded" />
      </div>
    );
  };
  
  const renderEnumField = (key, schema, register, baseInputClass, registerOptions) => {
    return (
      <select id={key} {...register(key, registerOptions)} className={baseInputClass}>
        {schema._def.values.map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </select>
    );
  };
  
  const renderDateField = (key, schema, register, baseInputClass, registerOptions) => {
    return <input id={key} {...register(key, { ...registerOptions, valueAsDate: true })} type="date" className={baseInputClass} />;
  };
  
  const renderLiteralField = (key, schema, baseInputClass) => {
    return <input id={key} type="text" value={schema._def.value} readOnly className={`${baseInputClass} bg-gray-100`} />;
  };
  
  const renderObjectField = (key, schema, register, errors) => {
    return (
      <fieldset key={key} className="border border-gray-300 p-4 rounded-md">
        <legend className="text-sm font-medium text-gray-700 px-2">{key}</legend>
        {zodSchemaToFormFields(schema, register, errors, key)}
      </fieldset>
    );
  };
  
  const renderArrayField = (key, schema, register, errors) => {
    return (
      <fieldset key={key} className="border border-gray-300 p-4 rounded-md">
        <legend className="text-sm font-medium text-gray-700 px-2">{key}</legend>
        {renderField(`${key}[0]`, schema.element, register, errors)}
      </fieldset>
    );
  };
  
  const renderFieldMeta = ({ isOptional, hasDefault, defaultValue }) => {
    return (
      <>
        {isOptional && <span className="ml-1 text-sm text-gray-500">(Optional)</span>}
        {hasDefault && <span className="ml-1 text-sm text-gray-500">(Default: {JSON.stringify(defaultValue)})</span>}
      </>
    );
  };
  
  export default zodSchemaToFormFields;