import { useAuth } from '@clerk/clerk-react';

export const useAuthenticatedFetch = () => {
  const { getToken } = useAuth();

  return async (url, options = {}) => {
    const token = await getToken();
    const { method = 'GET', headers = {}, body, ...restOptions } = options;

    const response = await fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
        Authorization: `Bearer ${token}`,
      },
      body: body ? JSON.stringify(body) : undefined,
      ...restOptions,
    });

    if (!response.ok) {
      throw new Error(`An error occurred while fetching the data: ${response.statusText}`);
    }

    return response.json();
  };
};